import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  quotesMessage: null,
  deleteMessage: { openDialog: false, messageId: '' },
  editMessage: null, // { channelType: '', channelId: '', messageId: '', messageText: '' },
  messageIdError: '',
  targetMessageId: '',
};

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    onReplyMessage(state, action) {
      state.quotesMessage = action.payload;
    },
    onDeleteMessage(state, action) {
      state.deleteMessage = action.payload;
    },
    onEditMessage(state, action) {
      state.editMessage = action.payload;
    },
    setMessageIdError(state, action) {
      state.messageIdError = action.payload;
    },
    setTargetMessageId(state, action) {
      state.targetMessageId = action.payload;
    },
  },
});

// Reducer
export const { onReplyMessage, onDeleteMessage, onEditMessage, setMessageIdError, setTargetMessageId } = slice.actions;

export default slice.reducer;
