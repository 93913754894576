import { createSlice } from '@reduxjs/toolkit';
import { ChatType, RoleMember, SidebarType, TabType } from '../../constants/commons-const';
import { client } from '../../client';
import { handleError } from '../../utils/commons';
import { CapabilitiesName, ErmisCapabilities } from '../../constants/capabilities-const';
import { setSidebar } from './app';

const initialState = {
  channels: [],
  channel_id: null,
  currentChannel: null,
  allUnreadData: {},
  markReadChannel: null,
  capabilities: [],
  channelPermissions: {
    canSendMessage: true,
    canEditMessage: true,
    canDeleteMessage: true,
    canReactMessage: true,
  },
  readyChannel: false,
};

const slice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    fetchChannels(state, action) {
      state.channels = action.payload;
    },
    setCurrentChannel(state, action) {
      state.currentChannel = action.payload;
    },
    addChannel(state, action) {
      state.channels.unshift(action.payload);
    },
    removeChannel(state, action) {
      state.channels = state.channels.filter(item => item.id !== action.payload);
      state.currentChannel = null;
      state.readyChannel = false;
    },
    fetchAllUnreadData(state, action) {
      state.allUnreadData = action.payload;
    },
    setMarkReadChannel(state, action) {
      state.markReadChannel = action.payload;
    },
    setCapabilities(state, action) {
      state.capabilities = action.payload;
    },
    setChannelPermissions(state, action) {
      const { canSendMessage, canEditMessage, canDeleteMessage, canReactMessage } = action.payload;
      state.channelPermissions.canSendMessage = canSendMessage;
      state.channelPermissions.canEditMessage = canEditMessage;
      state.channelPermissions.canDeleteMessage = canDeleteMessage;
      state.channelPermissions.canReactMessage = canReactMessage;
    },
    setReadyChannel(state, action) {
      state.readyChannel = action.payload;
    },
  },
});

// Reducer
export const { setCurrentChannel, removeChannel } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------

export function FetchChannels(params) {
  return async (dispatch, getState) => {
    if (!client) return;
    const { tab } = getState().app;

    const filter = {
      roles: tab === TabType.Chat ? [RoleMember.OWNER, RoleMember.MOD, RoleMember.MEMBER] : [RoleMember.PENDING],
    };

    if (params) {
      const { type } = params;
      filter.type = type ? type : ChatType.ALL;
    }

    const sort = [];
    const options = {
      // limit: 10,
      // offset: 0,
      message_limit: 25,
      // presence: true,
      // watch: true,
    };
    dispatch(slice.actions.fetchChannels([]));

    await client
      .queryChannels(filter, sort, options)
      .then(response => {
        const sortedArray = response.sort((a, b) => {
          const dateA = a.state.last_message_at ? new Date(a.state.last_message_at) : new Date(a.data.created_at);
          const dateB = b.state.last_message_at ? new Date(b.state.last_message_at) : new Date(b.data.created_at);
          return dateB - dateA;
        });
        dispatch(slice.actions.fetchChannels(sortedArray));
      })
      .catch(err => {
        dispatch(slice.actions.fetchChannels([]));
        handleError(dispatch, err);
      });
  };
}

export const SetChannels = payload => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.fetchChannels(payload));
  };
};

export const ConnectCurrentChannel = (channelId, channelType) => {
  return async (dispatch, getState) => {
    try {
      if (!client) return;
      dispatch(slice.actions.setReadyChannel(false));
      const { user_id } = getState().auth;
      const channel = client.channel(channelType, channelId);
      const read = channel.state.read[user_id];
      const lastMessageId = read && read.unread_messages > 0 ? read.last_read_message_id : '';

      const messages = { limit: 25 };
      if (lastMessageId) {
        messages.id_gt = lastMessageId;
      }

      // await channel.watch();
      const response = await channel.query({
        messages,
      });

      if (response) {
        dispatch(slice.actions.setReadyChannel(true));
        dispatch(setSidebar({ type: SidebarType.Channel, open: false }));
        dispatch(slice.actions.setCurrentChannel(channel));
        dispatch(SetMemberCapabilities(channel.data.member_capabilities));
      }
    } catch (error) {
      handleError(dispatch, error);
    }
  };
};

export const AddChannel = (channelId, channelType) => {
  return async (dispatch, getState) => {
    if (!client) return;
    dispatch(slice.actions.setReadyChannel(false));
    const channel = client.channel(channelType, channelId);

    const messages = { limit: 25 };
    const response = await channel.query({
      messages,
    });

    if (response) {
      dispatch(slice.actions.setReadyChannel(true));
      dispatch(slice.actions.addChannel(channel));
    }
  };
};

export const SetMarkReadChannel = channel => {
  return async (dispatch, getState) => {
    const responseMarkRead = await channel.markRead();
    dispatch(slice.actions.setMarkReadChannel(responseMarkRead?.event));
  };
};

export function FetchAllUnreadData() {
  return async (dispatch, getState) => {
    if (!client) return;

    const userId = getState().auth.user_id;
    await client
      .getUnreadCount(userId)
      .then(response => {
        dispatch(slice.actions.fetchAllUnreadData(response));
      })
      .catch(err => {
        handleError(dispatch, err);
      });
  };
}

export function SetMemberCapabilities(memberCapabilities) {
  return async (dispatch, getState) => {
    const { currentChannel } = getState().channel;

    const capabilities = memberCapabilities.filter(item => ErmisCapabilities.includes(item));
    dispatch(slice.actions.setCapabilities(capabilities));

    const membership = currentChannel.state.membership;
    if (membership.channel_role === RoleMember.MEMBER) {
      const canSendMessage = capabilities.includes(CapabilitiesName.SendMessage);
      const canEditMessage = capabilities.includes(CapabilitiesName.UpdateOwnMessage);
      const canDeleteMessage = capabilities.includes(CapabilitiesName.DeleteOwnMessage);
      const canReactMessage = capabilities.includes(CapabilitiesName.SendReaction);

      dispatch(
        slice.actions.setChannelPermissions({ canSendMessage, canEditMessage, canDeleteMessage, canReactMessage }),
      );
    } else {
      dispatch(
        slice.actions.setChannelPermissions({
          canSendMessage: true,
          canEditMessage: true,
          canDeleteMessage: true,
          canReactMessage: true,
        }),
      );
    }
  };
}
