import { createSlice } from '@reduxjs/toolkit';
import { UpdateIsLoading, showSnackbar } from './app';
import { CloseDialogProfile } from './dialog';
import { handleError } from '../../utils/commons';
import { client } from '../../client';

const initialState = {
  all_members: [],
  user: {},
  friend_ids: [],
  mention: {
    anchorEl: null,
    members: [],
    selectedIndex: 0,
    mentionIds: [],
  },
};

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    fetchAllMembers(state, action) {
      state.all_members = action.payload;
    },
    setAllMembers(state, action) {
      state.all_members = action.payload;
    },
    updateMember(state, action) {
      const data = action.payload;

      state.all_members = state.all_members.map(item => {
        if (item.id === data.id) {
          return { ...item, data };
        } else {
          return item;
        }
      });
    },
    fetchUser(state, action) {
      state.user = action.payload.user;
    },
    updateUser(state, action) {
      state.user = action.payload.user;
    },
    fetchFriends(state, action) {
      state.friend_ids = action.payload;
    },
    setSelectedIndexMention(state, action) {
      state.mention.selectedIndex = action.payload;
    },
    setMention(state, action) {
      state.mention = action.payload;
    },
    setMentionIds(state, action) {
      state.mention.mentionIds = action.payload;
    },
  },
});

// Reducer
export const { setSelectedIndexMention, setMention, setMentionIds } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------

export function SetAllMembers(payload) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateMember(payload));
  };
}

export function UpdateMember(payload) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateMember(payload));
  };
}

export function FetchAllMembers() {
  return async (dispatch, getState) => {
    if (!client) return;

    const page = 1;
    const page_size = 1000;
    const result = await client.queryUsers(page_size, page);

    if (result) {
      const { data } = result;
      dispatch(slice.actions.fetchAllMembers(data));
    }
  };
}

export function FetchUserProfile() {
  return async (dispatch, getState) => {
    if (!client) return;
    const { user_id } = getState().auth;
    const result = await client.queryUser(user_id);

    if (result) {
      dispatch(slice.actions.fetchUser({ user: result }));
    }
  };
}

export const UpdateUserProfile = formValues => {
  return async (dispatch, getState) => {
    if (!client) return;

    const file = formValues.avatar;
    dispatch(UpdateIsLoading({ isLoading: true }));
    let avatar;
    let isContinute = true;
    if (file) {
      try {
        const urlAvatar = await client.uploadFile(file);
        if (urlAvatar) {
          avatar = urlAvatar;
          dispatch(showSnackbar({ severity: 'success', message: 'Update profile successfully!' }));
        }
      } catch (error) {
        dispatch(UpdateIsLoading({ isLoading: false }));
        handleError(dispatch, error);
        isContinute = false;
      }
    } else {
      avatar = '';
    }

    if (isContinute) {
      try {
        const name = formValues.name;
        const about_me = formValues.about_me;
        const resUpdate = await client.updateProfile(name, about_me);

        if (resUpdate) {
          dispatch(slice.actions.updateUser({ user: resUpdate }));
          dispatch(FetchAllMembers());
          dispatch(showSnackbar({ severity: 'success', message: 'Update profile successfully!' }));
          dispatch(UpdateIsLoading({ isLoading: false }));
          dispatch(CloseDialogProfile());
        }
      } catch (error) {
        dispatch(UpdateIsLoading({ isLoading: false }));
        handleError(dispatch, error);
      }
    }
  };
};

export function FetchFriends() {
  return async (dispatch, getState) => {
    if (!client) return;
    const { projectCurrent } = getState().wallet;
    const project_id = projectCurrent?.project_id;

    await client
      .queryContacts()
      .then(response => {
        const user_ids = response.project_id_user_ids[project_id] || [];
        dispatch(slice.actions.fetchFriends(user_ids));
      })
      .catch(err => {
        handleError(dispatch, err);
      });
  };
}
