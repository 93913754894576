// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.spinner > svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.MuiButtonBase-root.Mui-disabled {
  opacity: 0.5;
}

.loader {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 25px;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #333;
  border-radius: 50%;
  animation: bounce 1.4s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.slideItem {
  place-items: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;;;IAGE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".spinner {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 16px;\n}\n\n.spinner > svg {\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\niframe#webpack-dev-server-client-overlay {\n  display: none;\n}\n\n.MuiButtonBase-root.Mui-disabled {\n  opacity: 0.5;\n}\n\n.loader {\n  display: inline-flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 25px;\n}\n\n.dot {\n  width: 5px;\n  height: 5px;\n  background-color: #333;\n  border-radius: 50%;\n  animation: bounce 1.4s infinite;\n}\n\n.dot:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.dot:nth-child(3) {\n  animation-delay: 0.4s;\n}\n\n@keyframes bounce {\n  0%,\n  80%,\n  100% {\n    transform: scale(0);\n  }\n  40% {\n    transform: scale(1);\n  }\n}\n\n.slideItem {\n  place-items: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
